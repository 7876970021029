
import { Vue, Component } from 'vue-property-decorator'
import Swiper from 'swiper'
@Component({
  components: {}
})
export default class Dash extends Vue {
  private mounted () {
    const mySwiper = new Swiper('.swiper-container', {
      // loop: true,
      autoplay: false,
      direction: 'vertical',
      mousewheel: true, // 开启鼠标滚轮控制Swiper切换。可设置鼠标选项，或true使用默认值。
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true // 修改swiper的父元素时，自动初始化swiper
      // observeSlideChildren: true
    })
  }
}
